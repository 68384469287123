import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login";
import Constraints from "@/views/Constraints";
import GiftPreferences from "@/views/GiftPreferences";
import DressCodes from "@/views/DressCodes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/constraints",
    name: "Constraints",
    component: Constraints,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/giftpreferences",
    name: "Gift preferences",
    component: GiftPreferences,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dresscodes",
    name: "Dress codes",
    component: DressCodes,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //console.log("to: " + JSON.stringify(to) + "; from: " + JSON.stringify(from) + "; next: " + next);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const loggedIn = localStorage.getItem("user") != null;
    if (!loggedIn) {
      return next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

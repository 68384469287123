import api from "../plugins/axios";
import authHeader from "../helpers/auth-header";

class WeddingService {
  createNewWedding(wedding) {
    return api.post("/wedding/", wedding, { headers: authHeader() });
  }

  updateWedding(wedding) {
    return api.put("/wedding/" + wedding.id, wedding, {
      headers: authHeader(),
    });
  }

  fetchWeddingById(id) {
    return api.get("/wedding/" + id, { headers: authHeader() });
  }

  fetchAllWeddings() {
    return api.get("/wedding/all", { headers: authHeader() });
  }

  fetchAllConstraints() {
    return api.get("/wedding/constraints", { headers: authHeader() });
  }

  fetchAllGiftPreferences() {
    return api.get("/wedding/giftpreferences", { headers: authHeader() });
  }

  fetchAllDressCodes() {
    return api.get("/wedding/dresscodes", { headers: authHeader() });
  }

  createNewConstraint(constraint) {
    return api.post("/wedding/constraints", constraint, {
      headers: authHeader(),
    });
  }

  createNewGiftPreference(giftPreference) {
    return api.post("/wedding/giftpreferences", giftPreference, {
      headers: authHeader(),
    });
  }

  createNewDressCode(dressCode) {
    return api.post("/wedding/dresscodes", dressCode, {
      headers: authHeader(),
    });
  }

  removeConstraint(id) {
    return api.delete("/wedding/constraints/" + id, { headers: authHeader() });
  }

  removeGiftPreference(id) {
    return api.delete("/wedding/giftpreferences/" + id, { headers: authHeader() });
  }

  removeDressCode(id) {
    return api.delete("/wedding/dresscodes/" + id, { headers: authHeader() });
  }

  addNewGuest(weddingId, guest) {
    return api.post("/wedding/" + weddingId + "/guests", guest, { headers: authHeader() });
  }

  removeGuest(weddingId, guestId) {
    return api.delete("/wedding/" + weddingId + "/guests/" + guestId, { headers: authHeader() });
  }

  updateGuest(weddingId, guestId, guest) {
    return api.put("/wedding/" + weddingId + "/guests/" + guestId, guest, { headers: authHeader() });
  }
}

export default new WeddingService();

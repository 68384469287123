import api from "../plugins/axios";

class AuthService {
  login(user) {
    return api
      .post("auth/public/login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return api.post("auth/public/register", {
      username: user.username,
      email: user.username,
      password: user.password,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }
}

export default new AuthService();

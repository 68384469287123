import api from "../plugins/axios";
import authHeader from "../helpers/auth-header";

class GuestsService {
  fetchAllGuests() {
    return api.get("/guests/private/list", { headers: authHeader() });
  }

  addNewGuest(guest) {
    return api.post("/guests/private/new", guest, { headers: authHeader() });
  }
}

export default new GuestsService();

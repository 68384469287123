<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped v-if="loggedIn">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Svadbonator</v-list-item-title>
          <v-list-item-subtitle>Wedding management system</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logUserOut()"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="primary" dark v-if="loggedIn">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer app v-if="loggedIn">
      <span>&copy; 2021 </span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({
    drawer: null,
    menuItems: [
      { title: "Home", icon: "mdi-view-dashboard", path: "/" },
      { title: "Constraints", icon: "mdi-view-dashboard", path: "/constraints" },
      { title: "Gift preferences", icon: "mdi-view-dashboard", path: "/giftpreferences" },
      { title: "Dress codes", icon: "mdi-view-dashboard", path: "/dresscodes" },
    ],
  }),

  methods: {
    ...mapActions("auth", ["logout"]),
    logUserOut() {
      this.logout();
      this.$router.go();
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="dressCodes"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import WeddingService from "@/services/wedding.service";
import moment from "moment";

export default {
  name: "DressCodes",
  data() {
    return {
      dressCodes: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created at", value: "createdAt" },
      ],
    };
  },

  methods: {
    initializeData() {
      WeddingService.fetchAllDressCodes().then((response) => {
        this.dressCodes = response.data;
      });
    },

    formatDate(value) {
      return moment.unix(value).format("DD/MM/YYYY  HH:mm:ss");
    },
  },

  mounted() {
    this.initializeData();
  },
};
</script>

<style scoped></style>

import GuestsService from "../../services/guests.service";

const actions = {
  fetchGuestList(context) {
    GuestsService.fetchAllGuests().then((response) => {
      context.commit("SET_GUEST_LIST", response.data);
    });
  },

  clearGuestList(context) {
    context.commit("SET_GUEST_LIST", []);
  },
};

const state = {
  guestList: [],
};

const getters = {
  getGuestList(state) {
    return state.guestList;
  }
};

const mutations = {
  SET_GUEST_LIST(state, payload) {
    state.guests = payload;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

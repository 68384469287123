<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="constraints"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Constraints</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="primary"
                small
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                New constraint
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.constraintName"
                        label="Constraint name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="blue darken-1" text @click="close()">
                  Cancel
                </v-btn>
                <v-btn rounded color="blue darken-1" text @click="save()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  rounded
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn rounded color="primary" @click="initializeData"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import WeddingService from "@/services/wedding.service";
import moment from "moment";

export default {
  name: "Constraints",
  data() {
    return {
      constraints: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "constraintName" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions" },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        constraintName: "",
      },
      defaultItem: {
        constraintName: "",
      },
    };
  },

  methods: {
    initializeData() {
      WeddingService.fetchAllConstraints().then((response) => {
        this.constraints = response.data;
      });
    },

    formatDate(value) {
      return moment.unix(value).format("DD/MM/YYYY  HH:mm:ss");
    },

    editItem(item) {
      this.editedIndex = this.constraints.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.constraints.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.constraints.splice(this.editedIndex, 1);
      WeddingService.removeConstraint(this.editedItem.id).then((response) => {
        this.constraints = response.data;
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.constraints[this.editedIndex], this.editedItem);
      } else {
        WeddingService.createNewConstraint(this.editedItem).then((response) => {
          this.constraints = response.data;
        });
      }
      this.close();
    },
  },

  mounted() {
    this.initializeData();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Constraint" : "Edit Constraint";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped></style>

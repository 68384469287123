<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" align-with-title @change="tabChanged">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="item in weddings" :key="item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="w in weddings" :key="w.name">
          <v-card flat>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-textarea v-model="w.description" dense>
                        <template v-slot:label>
                          <div>Description <small>(optional)</small></div>
                        </template>
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="w.groomName"
                        label="Groom name"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="w.brideName"
                        label="Bride name"
                        required
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="w.mainVenueName"
                        label="Main venue name"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="w.mainVenueAddress"
                        label="Main venue address"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="w.weddingState"
                        label="Wedding state"
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
<!--                  <v-row>-->
<!--                    <v-col cols="12">-->
<!--                      <div style="height: 300px; width: 600px"><Map /></div>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
                </v-container>
              </v-form>

              <v-data-table
                :headers="guestsHeaders"
                :items="w.guests"
              >
                <template v-slot:item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>

                <template v-slot:item.rsvpTimestamp="{ item }">
                  {{ formatDate(item.rsvpTimestamp) }}
                </template>

                <template v-slot:item.rsvp="{ item }">
                  <v-chip :color="getColor(item.rsvp)" small>
                    {{ item.rsvp }}
                  </v-chip>
                </template>

                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Guest list</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>

                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn rounded color="primary" @click="initializeData"> Reset </v-btn>
                </template>
              </v-data-table>

            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="primary" small dark class="mb-2" v-bind="attrs" v-on="on">
          New guest
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.firstName"
                  label="First name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.lastName"
                  label="Last name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.email"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.phone"
                  label="Phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field disabled
                  v-model="editedItem.seatId"
                  label="Seat ID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                RSVP:
                <v-radio-group v-model="editedItem.rsvp">
                  <v-radio
                    v-for="opt in RSVPOptions"
                    :key="opt"
                    :label="`${opt}`"
                    :value="opt"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox
                  v-model="editedItem.veganFood"
                  label="Vegan food"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox
                  v-model="editedItem.vegetarianFood"
                  label="Vegetarian food"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="editedItem.note">

                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="blue darken-1" text @click="close()">
            Cancel
          </v-btn>
          <v-btn rounded color="blue darken-1" text @click="save()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
        >Are you sure you want to delete this guest?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="blue darken-1" text @click="closeDelete"
          >Cancel</v-btn
          >
          <v-btn rounded color="blue darken-1" text @click="deleteItemConfirm"
          >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import WeddingService from "@/services/wedding.service";
//import Map from "@/components/Map";
import moment from "moment";

export default {
  name: "Weddings",
  components: {  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      tab: null,
      tabId: 0,
      weddings: [],
      RSVPOptions: ["YES", "NO", "UNKNOWN"],
      editedIndex: -1,
      editedItem: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        rsvp: "",
        veganFood: false,
        vegetarianFood: false,
        note: "",
        seatId: 0,
      },
      defaultItem: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        rsvp: "UNKNOWN",
        veganFood: false,
        vegetarianFood: false,
        note: "",
        seatId: 0,
      },
      guestsHeaders: [
        { text: "ID", value: "id" },
        { text: "First name", value: "firstName" },
        { text: "Last name", value: "lastName" },
        { text: "RSVP", value: "rsvp" },
        { text: "RSVP time", value: "rsvpTimestamp"},
        { text: "Vegan", value: "veganFood"},
        { text: "Vegetarian", value: "vegetarianFood"},
        { text: "Note", value: "note"},
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Seat ID", value: "seatId" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  methods: {
    initializeData() {
      WeddingService.fetchAllWeddings().then((response) => {
        this.weddings = response.data;
        console.log(response.data);
      });
    },

    formatDate(value) {
      console.log(value);
      if (value == null) return "";
      return moment.unix(value).format("DD/MM/YYYY  HH:mm:ss");
    },

    getColor(rsvp) {
      if (rsvp == "YES") {
        return "green";
      } else if (rsvp == "MAYBE") {
        return "yellow";
      } else if (rsvp == "NO") {
        return "red";
      } else {
        return "gray";
      }
    },

    editItem(item) {
      this.editedIndex = this.weddings[this.tabId].guests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.weddings[this.tabId].guests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      WeddingService.removeGuest(this.weddings[this.tabId].id, this.weddings[this.tabId].guests[this.editedIndex].id).then(() => {});
      this.weddings[this.tabId].guests.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex === -1) {
        console.log("NEW GUEST");
        //Object.assign(this.weddings[this.tabId].guests[this.editedIndex], this.editedItem);
        WeddingService.addNewGuest(this.weddings[this.tabId].id, this.editedItem).then(() => {});
        this.initializeData();
      } else {
        console.log("UPDATE GUEST");
        WeddingService.updateGuest(this.weddings[this.tabId].id, this.weddings[this.tabId].guests[this.editedIndex].id, this.editedItem).then(() => {});
        //GuestsService.addNewGuest(this.editedItem).then();
        this.initializeData();
      }
      this.close();
    },

    tabChanged(evt) {
      this.tabId = evt;
      console.log(this.weddings[this.tabId].name);
    }
  },

  mounted() {
    this.initializeData();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Guest" : "Edit Guest";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

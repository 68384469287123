import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css";
import moment from "moment-timezone";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    formatDate: (value) =>
      moment.unix(value).tz("Europe/Belgrade").format("DD/MM/YYYY  HH:mm:ss"),
  },
});

new Vue({
  router,
  store,
  vuetify,
  VueLayers,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-app id="inspire">
    <div class="pa-0" style="max-height: 100vh; overflow: hidden">
      <v-img
        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
        height="100px"
        width="100vw"
      ></v-img>
      <v-card
        class="mx-auto elevation-5 text-center pa-5"
        max-width="350"
        style="border-radius: 20px; position: relative; top: -50px"
      >
        <v-form
          id="login-form"
          ref="form"
          lazy-validation
          @submit.prevent="handleLogin"
        >
          <v-card-subtitle class="title black--text pa-1">
            Login to Svadbonator
          </v-card-subtitle>
          <v-text-field
            hide-details
            color="red"
            label="Username"
            v-model="loginRequest.username"
          ></v-text-field>
          <v-text-field
            color="red"
            label="Password"
            type="password"
            v-model="loginRequest.password"
          ></v-text-field>
          <v-card-actions>
            <v-btn
              dark
              block
              rounded
              type="submit"
              form="login-form"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LoginRequest from "@/models/auth/login-request";

export default {
  name: "Login",

  data: () => {
    return {
      loginRequest: new LoginRequest("", ""),
    };
  },

  computed: {
    ...mapState("auth", ["login"]),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    handleLogin() {
      this.$store.dispatch("auth/login", this.loginRequest).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped></style>

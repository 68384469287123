<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="giftPreferences"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import WeddingService from "@/services/wedding.service";
import formatDate from "@/helpers/utils";

export default {
  name: "GiftPreferences",
  data() {
    return {
      giftPreferences: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created at", value: "createdAt" },
      ],
    };
  },
  methods: {
    initializeData() {
      WeddingService.fetchAllGiftPreferences().then((response) => {
        this.giftPreferences = response.data;
      });
    },

    formatDate

  },

  mounted() {
    this.initializeData();
  },
};
</script>

<style scoped></style>

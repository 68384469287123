import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.store";
import guests from "./modules/guests.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    guests,
  },
});

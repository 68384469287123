<template>
  <Weddings />
</template>

<script>
import Weddings from "../components/Weddings";

export default {
  name: "Home",

  components: {
    Weddings,
  },
};
</script>
